import React from 'react';
import Title from '../../components/Title';
import { Box, Flex } from '@chakra-ui/react'; // Importa los componentes de Chakra UI que necesitas
import Menu from './Menu/Menu';
import ChatbotWrapper from '../../components/ChatbotWrapper'; // Importa ChatbotWrapper desde su ubicación

const Home = () => {
  // Inicializa la visibilidad del chatbot
  const initialChatVisibility = true;
    return (
        <Flex direction={{ base: 'column', md: 'row' }} minHeight="10vh">
            {/* Menú */}
            <Menu />

            {/* Contenido principal */}
            <Box flex="1" p={{ base: 4, md: 7 }}>
                <Title text="Inicio" />

                {/* Integra ChatbotWrapper */}
                <ChatbotWrapper isChatVisible={initialChatVisibility}/>
            </Box>
        </Flex>
    );
};

export default Home;
