import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h2`
  font-size:30px;
  margin: ${(props) => (props.border ? '16.5px 0' : '0')};
  color: #525252;
  font-family: 'Arial', sans-serif;
`;

const Title = ({ text, border }) =>

  <StyledTitle border={border}>{text}</StyledTitle>;
  
export default Title;
