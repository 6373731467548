import React, { useState } from 'react';
import { Button, Box } from '@chakra-ui/react';
import Chatbot from './Chatbot'; // Importa el componente Chatbot desde la ubicación correcta

// Estilo para el botón flotante
const floatingButtonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    padding: '10px',
    backgroundColor: '#800D61',
    color: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: 1000,
    minHeight: '80px',
    minWidth: '80px',
    fontSize: '20px', 

};

const ChatbotWrapper = ({ isChatVisible = false, onChatVisibilityChange }) => {
    // Si isChatVisible no se especifica, se inicia en false
    const [isVisible, setIsVisible] = useState(isChatVisible);

    // Función para alternar la visibilidad del chatbot
    const toggleChatVisibility = () => {
        const newVisibility = !isVisible;
        setIsVisible(newVisibility);
        if (onChatVisibilityChange) {
            onChatVisibilityChange(newVisibility);
        }
    };

    return (
        <>
            {/* Botón flotante para mostrar el chatbot */}
            {!isVisible && (
                <Button style={floatingButtonStyle} onClick={toggleChatVisibility}>
                    Chat
                </Button>
            )}

            {/* Renderiza el chatbot si isVisible es true */}
            {isVisible && (
                <Box
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        width: '300px',
                        zIndex: 1000,
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                        padding: '10px',
                    }}
                >
                    <Chatbot onClose={toggleChatVisibility} />
                </Box>
            )}
        </>
    );
};

export default ChatbotWrapper;
